import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as bundlesA4YGoKhqWUMeta } from "/app/pages/bundles.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as paymentGOKqM0A9FlMeta } from "/app/pages/checkout/payment.vue?macro=true";
import { default as contactUssMQ6gX1U2qMeta } from "/app/pages/contactUs.vue?macro=true";
import { default as _91id_93crsUlqoOcGMeta } from "/app/pages/custom/[id].vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as order_45placedZsgnlwokSFMeta } from "/app/pages/order-placed.vue?macro=true";
import { default as contentadp9coEM67Meta } from "/app/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedfyVjkMyN2QMeta } from "/app/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionfUZuDQdTDKMeta } from "/app/pages/processing-transaction.vue?macro=true";
import { default as _91id_93kVZKMJ3tbEMeta } from "/app/pages/products/[id].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as resetTi6jw7blwxMeta } from "/app/pages/reset.vue?macro=true";
import { default as staticxlIu8wY1KfMeta } from "/app/pages/static.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
import { default as wishlisttHOV2I8jKWMeta } from "/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: _404STh7p7T7N0Meta?.name ?? "404",
    path: _404STh7p7T7N0Meta?.path ?? "/404",
    meta: _404STh7p7T7N0Meta || {},
    alias: _404STh7p7T7N0Meta?.alias || [],
    redirect: _404STh7p7T7N0Meta?.redirect,
    component: () => import("/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accountIWnKuvcAuMMeta?.name ?? "account",
    path: accountIWnKuvcAuMMeta?.path ?? "/account",
    meta: accountIWnKuvcAuMMeta || {},
    alias: accountIWnKuvcAuMMeta?.alias || [],
    redirect: accountIWnKuvcAuMMeta?.redirect,
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundlesA4YGoKhqWUMeta?.name ?? "bundles",
    path: bundlesA4YGoKhqWUMeta?.path ?? "/bundles",
    meta: bundlesA4YGoKhqWUMeta || {},
    alias: bundlesA4YGoKhqWUMeta?.alias || [],
    redirect: bundlesA4YGoKhqWUMeta?.redirect,
    component: () => import("/app/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cart9SktLKPpbzMeta?.name ?? "cart",
    path: cart9SktLKPpbzMeta?.path ?? "/cart",
    meta: cart9SktLKPpbzMeta || {},
    alias: cart9SktLKPpbzMeta?.alias || [],
    redirect: cart9SktLKPpbzMeta?.redirect,
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: index7ZtduOIoVXMeta?.name ?? "checkout",
    path: index7ZtduOIoVXMeta?.path ?? "/checkout",
    meta: index7ZtduOIoVXMeta || {},
    alias: index7ZtduOIoVXMeta?.alias || [],
    redirect: index7ZtduOIoVXMeta?.redirect,
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentGOKqM0A9FlMeta?.name ?? "checkout-payment",
    path: paymentGOKqM0A9FlMeta?.path ?? "/checkout/payment",
    meta: paymentGOKqM0A9FlMeta || {},
    alias: paymentGOKqM0A9FlMeta?.alias || [],
    redirect: paymentGOKqM0A9FlMeta?.redirect,
    component: () => import("/app/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: contactUssMQ6gX1U2qMeta?.name ?? "contactUs",
    path: contactUssMQ6gX1U2qMeta?.path ?? "/contactUs",
    meta: contactUssMQ6gX1U2qMeta || {},
    alias: contactUssMQ6gX1U2qMeta?.alias || [],
    redirect: contactUssMQ6gX1U2qMeta?.redirect,
    component: () => import("/app/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93crsUlqoOcGMeta?.name ?? "custom-id",
    path: _91id_93crsUlqoOcGMeta?.path ?? "/custom/:id()",
    meta: _91id_93crsUlqoOcGMeta || {},
    alias: _91id_93crsUlqoOcGMeta?.alias || [],
    redirect: _91id_93crsUlqoOcGMeta?.redirect,
    component: () => import("/app/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placedZsgnlwokSFMeta?.name ?? "order-placed",
    path: order_45placedZsgnlwokSFMeta?.path ?? "/order-placed",
    meta: order_45placedZsgnlwokSFMeta || {},
    alias: order_45placedZsgnlwokSFMeta?.alias || [],
    redirect: order_45placedZsgnlwokSFMeta?.redirect,
    component: () => import("/app/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: contentadp9coEM67Meta?.name ?? "pages-key-content",
    path: contentadp9coEM67Meta?.path ?? "/pages/:key()/content",
    meta: contentadp9coEM67Meta || {},
    alias: contentadp9coEM67Meta?.alias || [],
    redirect: contentadp9coEM67Meta?.redirect,
    component: () => import("/app/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedfyVjkMyN2QMeta?.name ?? "payment-failed",
    path: payment_45failedfyVjkMyN2QMeta?.path ?? "/payment-failed",
    meta: payment_45failedfyVjkMyN2QMeta || {},
    alias: payment_45failedfyVjkMyN2QMeta?.alias || [],
    redirect: payment_45failedfyVjkMyN2QMeta?.redirect,
    component: () => import("/app/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionfUZuDQdTDKMeta?.name ?? "processing-transaction",
    path: processing_45transactionfUZuDQdTDKMeta?.path ?? "/processing-transaction",
    meta: processing_45transactionfUZuDQdTDKMeta || {},
    alias: processing_45transactionfUZuDQdTDKMeta?.alias || [],
    redirect: processing_45transactionfUZuDQdTDKMeta?.redirect,
    component: () => import("/app/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVZKMJ3tbEMeta?.name ?? "products-id",
    path: _91id_93kVZKMJ3tbEMeta?.path ?? "/products/:id()",
    meta: _91id_93kVZKMJ3tbEMeta || {},
    alias: _91id_93kVZKMJ3tbEMeta?.alias || [],
    redirect: _91id_93kVZKMJ3tbEMeta?.redirect,
    component: () => import("/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: index18IXo8oN8QMeta?.name ?? "products",
    path: index18IXo8oN8QMeta?.path ?? "/products",
    meta: index18IXo8oN8QMeta || {},
    alias: index18IXo8oN8QMeta?.alias || [],
    redirect: index18IXo8oN8QMeta?.redirect,
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetTi6jw7blwxMeta?.name ?? "reset",
    path: resetTi6jw7blwxMeta?.path ?? "/reset",
    meta: resetTi6jw7blwxMeta || {},
    alias: resetTi6jw7blwxMeta?.alias || [],
    redirect: resetTi6jw7blwxMeta?.redirect,
    component: () => import("/app/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticxlIu8wY1KfMeta?.name ?? "static",
    path: staticxlIu8wY1KfMeta?.path ?? "/static",
    meta: staticxlIu8wY1KfMeta || {},
    alias: staticxlIu8wY1KfMeta?.alias || [],
    redirect: staticxlIu8wY1KfMeta?.redirect,
    component: () => import("/app/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verifyuQf3dnBI8DMeta?.name ?? "verify",
    path: verifyuQf3dnBI8DMeta?.path ?? "/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    alias: verifyuQf3dnBI8DMeta?.alias || [],
    redirect: verifyuQf3dnBI8DMeta?.redirect,
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlisttHOV2I8jKWMeta?.name ?? "wishlist",
    path: wishlisttHOV2I8jKWMeta?.path ?? "/wishlist",
    meta: wishlisttHOV2I8jKWMeta || {},
    alias: wishlisttHOV2I8jKWMeta?.alias || [],
    redirect: wishlisttHOV2I8jKWMeta?.redirect,
    component: () => import("/app/pages/wishlist.vue").then(m => m.default || m)
  }
]